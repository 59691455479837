<script setup >
const { locale, locales } = useI18n();
const { cached_data, updateData, page_loading,fetchInitialData,halt } =  useSettings("App");
const preloading = ref(false);
const waiting = ref(true);
const storyblokApi = useStoryblokApi()




const { data } = await useAsyncData(`global-settings-${locale.value}`, async () => {
    await fetchInitialData();
    return true
  })
  // globalData.value = data.value


// console.log("app header_color",cached_data.value.header_color)
watch(
  locale,
  async() => {
    // preloading.value = true;
    // await updateData();
    // console.log("refetch initial")
    // await fetchInitialData();
    // return true
    // preloading.value = false;
  },
  { deep: true }
);
watch(cached_data, () => {
  
}, { deep: true })
// //  fetchInitialData();
//   const main = await storyblokApi.get('cdn/stories/main', {
//                 version: "draft",
//                 resolve_links: '1',
//                 language: locale.value == 'en' ? '' : locale.value,
//                 resolve_relations: 'PageLink.story',
//             })
</script>
<template>
  <NuxtLayout :class="{'loading':cached_data.loading || page_loading }" v-if="!halt && cached_data.loaded">

    <NuxtPage :class="{ 'preloading-data': preloading }" />
  </NuxtLayout>
</template>
  
<style lang="scss">
.ajv.loading {
  pointer-events: none;
  // opacity: .5;
  // transform: scale(.5);
}
.page-enter-active,
.page-leave-active {
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: grayscale(1);
}
</style>
